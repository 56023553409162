
































import { ValidationProvider } from "vee-validate";
import { Component, Vue, Prop, Emit, Watch, Ref } from "vue-property-decorator";

@Component({
  components: {
    ValidationProvider,
    Tag: () => import("@/components/general/tag.vue"),
  },
})
export default class FormTag extends Vue {
  @Prop() name?: string;

  @Prop() value?: string[];

  @Prop() placeholder?: string;

  @Prop() rules?: string;

  @Prop() label?: string;

  @Prop({ default: false }) disabled!: boolean;

  @Ref() readonly provider!: InstanceType<typeof ValidationProvider>;

  localValue: string[] = [];

  search = "";

  @Emit("input")
  private handleInput(): string[] {
    this.provider.validate();

    return this.localValue;
  }

  @Emit("value")
  handleValueChanged(newValue: string[]) {
    this.localValue = newValue;
  }

  private handleEnter(e: KeyboardEvent) {
    if (this.search.replace(" ", "").length === 0) {
      return;
    }

    if (this.localValue.findIndex((value: string) => value === this.search) !== -1) {
      return;
    }

    this.localValue.push(this.search);

    this.search = "";

    this.handleInput();
  }

  private deSelectValue(value: string) {
    this.localValue = this.localValue.filter((val: string) => val !== value);

    this.handleInput();
  }

  private get validateAs(): string {
    if (this.label) {
      return this.label;
    }

    if (this.placeholder) {
      return this.placeholder;
    }

    if (this.name) {
      return this.name;
    }

    return `select field`;
  }
}
