













































import { ValidationProvider } from "vee-validate";
import { Component, Vue, Prop, Emit, Watch, Ref } from "vue-property-decorator";

@Component({
  components: {
    ValidationProvider,
  },
})
export default class FormSingleImageInput extends Vue {
  @Prop() name?: string;

  @Prop() value?: File | Media;

  @Prop() rules?: string | object;

  @Prop() label?: string;

  @Prop({ default: false }) disabled!: boolean;

  @Prop({ default: 158 }) height!: number;

  @Prop({ default: 63 }) previewHeight!: number | "cover";

  @Prop({ default: "default" }) type!: "default" | "profile";

  @Ref() fileInput!: HTMLInputElement;

  fileObj: { file: File | null; image?: string; id?: number } = { file: null, image: "" };

  isDraggedOver = false;

  @Emit("input")
  private handleInput() {
    return this.fileObj.file;
  }

  @Watch("value", { immediate: true })
  private valueChanged(newFile: File | Media) {
    if (!newFile) {
      return (this.fileObj = { file: null, image: "" });
    }

    if (newFile instanceof File) {
      this.fileObj.file = newFile;
      this.getPreviewImage(this.fileObj);
    } else {
      this.fileObj.id = newFile.id;
      this.fileObj.image = newFile.path;
    }
  }

  private dropFiles(event: DragEvent) {
    this.isDraggedOver = false;
    if (event?.dataTransfer?.files) {
      this.addFiles(event.dataTransfer.files);
    }
  }

  private dragover() {
    this.isDraggedOver = true;
  }

  private dragleave() {
    this.isDraggedOver = false;
  }

  private onFileUpload() {
    let files: FileList | null = this.fileInput.files;

    if (files) {
      this.addFiles(files);
    }
  }

  private addFiles(files: FileList) {
    this.fileObj.file = files[0];
    if (!this.fileObj.file) {
      return;
    }
    this.getPreviewImage(this.fileObj);
    this.handleInput();
  }

  private removeFile() {
    this.fileObj.file = null;
    this.fileObj.image = "";
    this.handleInput();
  }

  private getPreviewImage(fileObj: { file: File | null; image?: string }) {
    if (!fileObj.file) {
      return;
    }

    const reader: FileReader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === "string") {
        this.resizeImage(reader.result, 500, 500, fileObj);
      }
    };
    reader.readAsDataURL(fileObj.file);
  }

  private resizeImage(src: string, maxWidth: number, maxHeight: number, fileObj: { file: File | null; image?: string }) {
    if (!fileObj.file) {
      return;
    }
    const canvas = document.createElement("canvas");
    const img = new Image();
    const ctx = canvas.getContext("2d");
    img.onload = () => {
      const resize = this.calculateAspectRatioFit(img.width, img.height, maxWidth, maxHeight);
      canvas.width = resize.width;
      canvas.height = resize.height;
      ctx?.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);
      fileObj.image = canvas.toDataURL(fileObj?.file?.type, 0.8);
    };
    img.src = src;
  }

  private calculateAspectRatioFit(srcWidth: number, srcHeight: number, maxWidth: number, maxHeight: number) {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return {
      width: srcWidth * ratio,
      height: srcHeight * ratio,
    };
  }

  private get validateAs(): string {
    if (this.label) {
      return this.label;
    }

    if (this.name) {
      return this.name;
    }

    return `Image field`;
  }

  private get previewStlye() {
    if (this.previewHeight !== "cover") {
      return { height: `${this.previewHeight}px`, marginBottom: "16px" };
    }

    return { width: "100%", height: "100%", objectFit: "cover" };
  }
}
