



















































































import { ValidationProvider } from "vee-validate";
import { Component, Vue, Prop, Emit, Watch, Ref } from "vue-property-decorator";

@Component({
  components: {
    ValidationProvider,
  },
})
export default class FormSelect extends Vue {
  @Prop() name?: string;

  @Prop() value?: number[] | number;

  @Prop() placeholder?: string;

  @Prop() rules?: string;

  @Prop() label?: string;

  @Prop({ default: false }) disabled!: boolean;

  @Prop({ default: false }) multiple!: boolean;

  @Prop({ default: false }) options!: SelectOption[];

  @Ref() input!: HTMLInputElement;

  @Ref() readonly provider!: InstanceType<typeof ValidationProvider>;

  localValue: number[] | number = [];

  open = false;

  focused = false;

  search = "";

  private get validateAs(): string {
    if (this.label) {
      return this.label;
    }

    if (this.placeholder) {
      return this.placeholder;
    }

    if (this.name) {
      return this.name;
    }

    return `select field`;
  }

  private get localOptions(): SelectOption[] {
    return this.options.filter((option: SelectOption) => option.label.toLowerCase().includes(this.search.toLowerCase()));
  }

  private get selectedValues(): SelectOption[] {
    return this.options.filter((option: SelectOption) => {
      if (typeof this.localValue === "object") {
        return this.localValue.includes(option.id);
      }
      if (typeof this.localValue === "number") {
        return this.localValue === option.id;
      }
    });
  }

  private get tagStyle() {
    if (this.multiple) {
      return { padding: "2px 8px", borderRadius: "2px", backgroundColor: "#FFFFFF", fontSize: "14px", fontWeight: 500, color: "#363636" };
    }
    return {};
  }

  private selectValue(option: SelectOption) {
    if (this.multiple && typeof this.localValue === "object") {
      this.localValue = this.pushOrSlice(this.localValue, option.id);
      this.open = false;
      return this.handleInput();
    }

    if (!this.multiple) {
      this.localValue = option.id;
      this.open = false;
      return this.handleInput();
    }

    console.error("localValue of the select option is not the correct type");
  }

  private deSelectValue(option: SelectOption) {
    if (this.multiple && typeof this.localValue === "object") {
      this.localValue = this.pushOrSlice(this.localValue, option.id);
      return this.handleInput();
    }

    if (!this.multiple) {
      this.localValue = NaN;
      return this.handleInput();
    }

    console.error("localValue of the select option is not the correct type");
  }

  private pushOrSlice(array: number[], prop: number): number[] {
    if (array.includes(prop)) {
      array.splice(array.indexOf(prop), 1);
    } else {
      array.push(prop);
    }

    return array;
  }

  @Emit("input")
  private handleInput(): number[] | number {
    this.provider.validate();
    this.$forceUpdate();
    return this.localValue;
  }

  @Emit("focus")
  private handleFocus(e: Event): Event {
    this.open = true;
    this.focused = true;
    return e;
  }

  @Emit("blur")
  private handleBlur(e: Event): Event {
    // TODO: This is awkward
    this.focused = false;
    setTimeout(() => {
      this.open = false;
    }, 200);
    return e;
  }

  @Watch("value", { immediate: true })
  private valueChanged(newValue: number[] | number) {
    this.localValue = newValue;
  }
}
