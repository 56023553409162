







import { Component, Vue, Ref } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { extend, localize } from "vee-validate";
import nl from "vee-validate/dist/locale/nl.json";
localize("nl", nl);

import * as rules from "vee-validate/dist/rules";

interface Rules {
  [key: string]: any;
}
const customRules: Rules = rules;
Object.keys(customRules).forEach((rule: string) => {
  extend(rule, customRules[rule]);
});

for (const [rule, validation] of Object.entries(rules)) {
  extend(rule, { ...validation });
}

extend("url", {
  validate(value: string) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i",
    ); // fragment locator
    return pattern.test(value);
  },
  message: "De opgegeven URL is niet geldig.",
});

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    FormButton: () => import("./submit.vue"),
  },
})
export default class FormBase extends Vue {
  @Ref() readonly observer!: InstanceType<typeof ValidationObserver>;

  loading = false;

  success = false;

  reset() {
    this.loading = false;
    this.success = false;
  }

  async submit(): Promise<boolean> {
    this.loading = true;
    this.success = false;
    const isValid: boolean = await this.observer.validate();

    if (!isValid) {
      return (this.loading = false);
    }

    this.$emit("submit", isValid);

    return isValid;
  }
}
