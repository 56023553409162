







































import { ValidationProvider } from "vee-validate";
import { Component, Vue, Prop, Emit, Watch, Ref } from "vue-property-decorator";

@Component({
  components: {
    ValidationProvider,
  },
})
export default class FormSingleFileInput extends Vue {
  @Prop() name?: string;

  @Prop() value?: File | Media;

  @Prop() rules?: string | object;

  @Prop() label?: string;

  @Prop({ default: false }) disabled!: boolean;

  @Prop({ default: 158 }) height!: number;

  @Ref() fileInput!: HTMLInputElement;

  @Ref() readonly provider!: InstanceType<typeof ValidationProvider>;

  fileObj: { file: File | null; id?: number } = { file: null };

  isDraggedOver = false;

  @Emit("input")
  private handleInput() {
    return this.fileObj.file;
  }

  @Watch("value", { immediate: true })
  private valueChanged(newFile: File | Media) {
    if (!newFile) {
      return (this.fileObj = { file: null });
    }

    if (newFile instanceof File) {
      this.fileObj.file = newFile;
    } else {
      this.fileObj.id = newFile.id;
    }
  }

  private dropFiles(event: DragEvent) {
    this.isDraggedOver = false;
    if (event?.dataTransfer?.files) {
      this.addFiles(event.dataTransfer.files);
    }
  }

  private dragover() {
    this.isDraggedOver = true;
  }

  private dragleave() {
    this.isDraggedOver = false;
  }

  private onFileUpload() {
    let files: FileList | null = this.fileInput.files;

    if (files) {
      this.addFiles(files);
    }
  }

  private addFiles(files: FileList) {
    this.fileObj.file = files[0];
    this.provider.syncValue(this.fileObj.file);
    if (!this.fileObj.file) {
      return;
    }
    this.handleInput();
  }

  private removeFile() {
    this.fileObj.file = null;
    this.handleInput();
  }

  private get uploadStyle() {
    if (!!this.fileObj.file) {
      return {};
    }

    return { height: `${this.height}px` };
  }

  private get validateAs(): string {
    if (this.label) {
      return this.label;
    }

    if (this.name) {
      return this.name;
    }

    return `File field`;
  }
}
